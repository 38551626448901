import { makeStyles } from "../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    palette,
    breakpoints: { values, down: below },
  } = theme;
  return {
    container: {
      width: "100% !important",
      margin: "0px !important",
    },
    margin_top: {
      paddingTop: `${spacing(10)}px !important`,
    },
    header: {
      [below(values.sm)]: {
        fontSize: "28px !important",
      },
    },
    subheader: {
      fontSize: "28px !important",
      lineHeight: "normal",
      [below(values.md)]: {
        fontSize: "18px !important",
      },
    },
    sponsor_container: {
      display: "flex",
      justifyContent: "flex-end",
      marginLeft: `${spacing(5)}px !important`,
      marginRight: `${spacing(5)}px !important`,
      [below(values.md)]: {
        marginLeft: `${spacing(2)}px !important`,
        marginRight: `${spacing(2)}px !important`,
      },
      "& [class^='sponsor_container']": {
        width: "fit-content !important",
        display: "flex",
        columnGap: spacing(2),
      },
    },
    breadcrumbs_container: {
      borderBottom: "1px solid #EFEFEF",
      marginBottom: `${spacing(5)}px !important`,
      marginLeft: `${spacing(5)}px !important`,
      marginRight: `${spacing(5)}px !important`,
      [below(values.md)]: {
        marginLeft: "0px !important",
        marginRight: "0px !important",
        paddingLeft: `${spacing(2)}px !important`,
      },
    },
    header_container: {
      textAlign: "center",
    },
    body_container: {
      [below(values.md)]: {
        paddingLeft: `${spacing(2)}px !important`,
        paddingRight: `${spacing(2)}px !important`,
      },
    },
    carousel_and_ads: {
      padding: `${spacing(5)}px ${spacing(4)}px !important`,
      [below(1025)]: {
        padding: `${spacing(5)}px 0px !important`,
      },
    },
    article_preview_grid: {
      width: "100%",
      maxWidth: `${theme.spacing(138.125)}px !important`,
    },
    audio_container: {
      padding: spacing(1),
      "@media print": {
        display: "none",
      },
    },
    sponsored_content_container: {
      textAlign: "right",
      paddingBottom: spacing(2),
      paddingRight: spacing(5),
      [below(values.md)]: {
        paddingRight: spacing(2),
      },
    },
    edit_container: {
      marginRight: `${spacing(5)}px !important`,
      marginBottom: `${spacing(3)}px !important`,
      [below(values.md)]: {
        marginRight: `${spacing(2)}px !important`,
      },
    },
    related_news_container: {
      paddingTop: `${spacing(4)}px !important`,
      paddingBottom: `${spacing()}px !important`,
      [below(values.md)]: {
        paddingTop: `${spacing(2)}px !important`,
      },
    },
    tag_container: {
      display: "flex",
      justifyContent: "center",
      marginBottom: `${spacing(5)}px !important`,
      [below(values.md)]: {
        marginBottom: `${spacing(4)}px !important`,
      },
    },
  };
});

export { useStyles };
