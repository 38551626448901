import { makeStyles } from "../../../Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    palette,
    breakpoints: { down: below, values, between: between },
  } = theme;

  return {
    image_container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      width: "100%",
      height: "500px",
      position: "relative",
      "& img": {
        width: "auto",
        height: "auto",
        maxWidth: "100%",
        maxHeight: "100%",
      },
      [below(values.md)]: {
        height: "300px",
      },
    },
    caption_credits: {
      left: "auto",
      right: "auto",
      display: "grid",
      padding: 10,
      [`@media print`]: {
        left: spacing(6.25),
      },
    },
    pointer_cursor: {
      cursor: "pointer",
    },
    download: {
      display: "flex",
      fontWeight: 600,
      [between(660, 726)]: {
        display: "none",
      },
    },
    download_icon: {
      width: spacing(3),
      height: spacing(3),
    },
    download_link: {
      alignSelf: "center",
      width: spacing(10),
    },
    show_pdf_container: {
      width: spacing(15),
      position: "absolute",
      bottom: 12,
      right: 12,
      "& > .MuiButton-root": {
        paddingLeft: spacing(0.5),
        paddingRight: spacing(0.5),
      },
    },
    full_size: {
      width: "100%",
      position: "relative",
    },
    show_pdf_button: {
      backgroundColor: `${palette.dark.black} !important`,
    },
    full_pdf_button: {
      width: "100%",
      backgroundColor: `${palette.dark.black} !important`,
    },
    arrows_container: {
      height: spacing(7),
      [below(values.md)]: {
        height: spacing(4),
      },
    },
  };
});

export { useStyles };
