import restClient from "../utils/restClient";
import withConfig from "../utils/withConfig";

export const checkIfArticleIsLockedOut = async (articleId) => {
  const response = await restClient.get(
    `${withConfig("LOCKED_BY").replace(":id", articleId)}`
  );

  return response;
};

export const submitDraftArticle = async () => {
  let form = document.querySelector("#article-form");
  let data = new FormData(form);

  let articleBody = document.querySelectorAll(".ck-editor__editable")[0];
  let articleBodyInstance = articleBody?.ckeditorInstance;
  let promotionalFooter = document.querySelectorAll(".ck-editor__editable")[1];
  let promotionalFooterInstance = promotionalFooter?.ckeditorInstance;

  data.set("article[body]", articleBodyInstance.getData());

  if (promotionalFooterInstance) {
    data.set(
      "article[promotional_footer]",
      promotionalFooterInstance.getData()
    );
  }

  try {
    const response = await restClient.post(
      `${withConfig("CMS_UPDATE_ARTICLE_DRAFT")}`,
      data
    );

    return response;
  } catch (e) {
    console.error({ e });
    return { message: e?.response?.data?.message, status: e?.response?.status };
  }
};

export const fetchShowArticle = async (payload) => {
  const response = await restClient.get(
    withConfig("FETCH_SHOW_ARTICLE").replace(":id", payload?.id),
    payload
  );
  return response;
};

export const fetchRelatedNews = async (payload) => {
  const response = await restClient.get(
    withConfig("FETCH_RELATED_NEWS"),
    payload
  );
  return response;
};

export const fetchMostReadArticles = async (payload) => {
  const response = await restClient.get(
    withConfig("FETCH_MOST_READ_ARTICLES"),
    payload
  );
  return response;
};

export const submitSlugSettings = async (payload) => {
  const response = await restClient.post(
    withConfig("SUBMIT_SLUG_URL_SETTINGS"),
    payload
  );
  return response;
};

export const fetchSlugSettings = async (payload) => {
  const response = await restClient.get(
    withConfig("FETCH_SLUG_URL_SETTINGS"),
    payload
  );
  return response;
};
