import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    palette,
    breakpoints: { down: below, values },
  } = theme;
  return {
    grid_container: {
      justifyContent: "space-evenly",
      [below(values.sm)]: {
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      },
      rowGap: spacing(4),
      paddingBottom: spacing(4),
      borderBottom: `2px solid ${palette.themeColor.primary}`,
      marginBottom: spacing(5),
      marginTop: spacing(2),
      borderTop: `2px solid ${palette.themeColor.primary}`,
    },
    image_link: {
      display: "flex",
      alignItems: "center",
    },
    information_container: {
      flexDirection: "row !important",
      gap: `${spacing(3)}px`,
    },
    media_content: {
      width: `${spacing(67.75)}px !important`,
      [below(1446)]: {
        width: `${spacing(58)}px !important`,
      },
      [below(1238)]: {
        width: `${spacing(44)}px !important`,
        height: `${spacing(38.8)}px !important`,
      },
      height: `${spacing(37.5)}px !important`,
    },
    article_media_content: {
      width: "100%",
      height: "100%",
      maxWidth: `${spacing(67.75)}px !important`,
      [below(1446)]: {
        maxWidth: `${spacing(58)}px !important`,
      },
      [below(1238)]: {
        maxWidth: `${spacing(44)}px !important`,
        maxHeight: `${spacing(38.8)}px !important`,
      },
      maxHeight: `${spacing(37.5)}px !important`,
    },
    container: {
      width: `${spacing(67.75)}px !important`,
      [below(1446)]: {
        width: `${spacing(58)}px !important`,
      },
      [below(1238)]: {
        width: `${spacing(44)}px !important`,
        alignSelf: "center",
      },
      rowGap: `${spacing(2.5)}px !important`,
    },
    text_label_default: {
      width: "100%",
      fontWeight: 800,
      fontSize: spacing(2.5),
      lineHeight: "24.38px",
      letterSpacing: "0.05em",
      textAlign: "center",
      color: palette.miscellaneous.black,
      borderTop: `1px solid ${palette.light.gray}`,
      paddingTop: spacing(5),
      paddingBottom: spacing(4),
      marginBottom: "0",
    },
    text_label_layout_1: {
      fontWeight: 700,
      lineHeight: "29.26px",
      letterSpacing: "0.24em",
      color: palette.miscellaneous.white,
      padding: "0px !important",
      margin: "0px !important",
    },
    text_label_layout_2: {
      fontWeight: 700,
      lineHeight: "29.26px",
      color: palette.themeColor.primary,
    },
    text_label_layout_3: {
      fontWeight: 700,
      fontSize: spacing(4.25),
      lineHeight: "42.67px",
      letterSpacing: "0.10em",
      color: palette.miscellaneous.black,
      padding: "0px !important",
      margin: "0px !important",
    },
    text_label_layout_4: {
      fontWeight: 700,
      lineHeight: "29.26px",
      color: palette.themeColor.primary,
    },
    text_label_layout_5: {
      fontWeight: 800,
      lineHeight: "29.26px",
      letterSpacing: "0.05em",
      color: palette.themeColor.primary,
      paddingTop: `${spacing(3)}px !important`,
      paddingBottom: `${spacing(3)}px !important`,
      paddingLeft: "0px !important",
    },
    list_related_news: {
      rowGap: spacing(3),
    },
    no_printing: {
      "@media print": {
        display: "none !important",
      },
    },
    container_layout_2: {
      margin: "0px !important",
      padding: "0px !important",
      width: "100% !important",
      "@media print": {
        display: "none !important",
      },
    },
    container_layout_4: {
      margin: "0px !important",
      padding: "0px !important",
      width: "100% !important",
      [below("md")]: {
        padding: `0px ${spacing(2)}px !important`,
      },
      "@media print": {
        display: "none !important",
      },
    },
    container_layout_5: {
      margin: "0px !important",
      padding: "0px !important",
      marginBottom: `${spacing(3)}px !important`,
      width: "100% !important",
      [below("md")]: {
        padding: `0px ${spacing(2)}px !important`,
      },
      "@media print": {
        display: "none !important",
      },
    },

    related_news_layout_1: {
      backgroundColor: "black",
      padding: "24px",
      rowGap: "24px",
      [below(values.md)]: {
        padding: "24px 0px",
      },
      "@media print": {
        display: "none !important",
      },
    },
    label_container_layout_1: {
      paddingLeft: "8px !important",
    },
    related_news_layout_3: {
      padding: "24px",
      rowGap: "24px",
      [below(values.md)]: {
        padding: "24px 0px",
      },
      "@media print": {
        display: "none !important",
      },
    },
    label_container_layout_3: {
      paddingLeft: "8px !important",
    },
    label_container_layout_4: {
      paddingLeft: "0px !important",
    },
  };
});

export { useStyles };
