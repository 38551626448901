import { makeStyles } from "../../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    palette,
    breakpoints: { values, down: below },
  } = theme;
  return {
    header: {
      fontSize: "40px !important",
      letterSpacing: "0.8px !important",
      lineHeight: "normal",
      [below(values.sm)]: {
        fontSize: "24px !important",
      },
    },
    subheader: {
      fontSize: "24px !important",
      lineHeight: "normal",
      [below(values.sm)]: {
        fontSize: "18px !important",
      },
    },
    sponsor_container: {
      display: "flex",
      justifyContent: "flex-end",
      "& [class^='sponsor_container']": {
        width: "fit-content !important",
        display: "flex",
        columnGap: spacing(2),
        "& > span": {
          color: "white !important",
        },
      },
    },
    top_section: {
      backgroundColor: palette.grayVariation.grayLightFooter,
      padding: `${spacing(8)}px ${spacing(10)}px !important`,
      [below(values.md)]: {
        padding: `${spacing(8)}px ${spacing(2)}px !important`,
      },
    },
    breadcrumbs_container: {
      borderBottom: "1px solid white",
      marginBottom: `${spacing(5)}px !important`,
    },
    tag_container: {
      marginBottom: `${spacing(5)}px !important`,
    },
    header_container: {
      marginBottom: `${spacing(2)}px !important`,
    },
    article_content_container: {
      borderLeft: `1px solid ${palette.grayVariation.grayForBorder}`,
      borderRight: `1px solid ${palette.grayVariation.grayForBorder}`,
      borderTop: `1px solid ${palette.grayVariation.grayForBorder}`,
      paddingLeft: "3% !important",
      paddingRight: "3% !important",
      paddingTop: `${spacing(7)}px !important`,
      marginBottom: `${spacing(4)}px !important`,
      marginTop: `-${spacing(5)}px !important`,
      backgroundColor: "white",
      [below(values.sm)]: {
        padding: `${spacing(2)}px !important`,
        border: "none",
      },
    },
    article_preview_grid: {
      width: "100%",
      maxWidth: `${theme.spacing(138.125)}px !important`,
    },
    related_news: {
      [below(values.md)]: {
        padding: `${spacing(2)}px !important`,
      },
    },
    article_body_container: {
      [below(values.md)]: {
        paddingLeft: `${spacing(2)}px !important`,
      },
    },
    sponsored_content_container: {
      textAlign: "right",
      padding: `${spacing(2)}px 0px !important`,
    },
    container: {
      [below(values.md)]: {
        paddingBottom: `${spacing(3)}px !important`,
      },
    },
    audio_container: {
      padding: `${spacing(1)}px !important`,
      "@media print": {
        display: "none",
      },
    },
    byline_container: {
      marginTop: `${spacing(3)}px !important`,
      [below(values.md)]: {
        marginTop: "0px !important",
      },
    },
    edit_container: {
      marginBottom: `${spacing(2)}px !important`,
    },
  };
});

export { useStyles };
