import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { screenWidthIsLowerThan } from "../../../../hooks/useMediaQuery";
import { useStyles } from "./Layout5.style";
import ArticleBody from "../../ArticleBody";
import ArticlesCarousel from "../../../../common/Carousel/Articles/ArticlesCarousel";
import AudioPlayer from "../../../AudioPlayer/AudioPlayer";
import Breadcrumbs from "../../../../common/Breadcrumbs/Breadcrumbs";
import ByLine from "../../ByLine/ByLine";
import Disclaimer from "../../Disclaimer/Disclaimer";
import Edit from "../../../Actions/Edit";
import Grid from "../../../../common/Grid/Grid";
import labels from "../../../../config/labels";
import Leaderboard from "../Components/Leaderboard/Leaderboard";
import React, { useMemo } from "react";
import RelatedNews from "../../RelatedNews/RelatedNews";
import SectionTag from "../../SectionTag/SectionTag";
import Sponsor from "../../../Sponsor/Sponsor";
import StickySocialNetworkShareButtons from "../../../StickySocialNetworkShareButtons/StickySocialNetworkShareButtons";
import TopAds from "../Components/TopAds/TopAds";
import Typography from "../../../../common/Typography/Typography";
import values from "../../../../common/Theme/themes/breakpoints/values";
import withConfig from "../../../../utils/withConfig";
import PromotionalFooter from "../Components/PromotionalFooter/PromotionalFooter";
import EditorNotes from "../Components/EditorNotes/EditorNotes";
import { object } from "prop-types";
import clsx from "clsx";
import { HEADER_CONTAINER_SELECTOR } from "../../../../utils/constants/trinityAudioConstants";
import MostRead from "../../MostRead/MostRead";

const Layout5 = ({ article, props, sponsor }) => {
  const {
    article_body,
    category_name,
    subscribe_to_content,
    is_article_preview,
    is_town,
    is_premium,
    ad_targets,
    article_disclaimer,
    disclaimer_policy_page_links,
    town_editor_email,
    can_edit,
    edit_url,
    video_ad_content,
    breadcrumbs,
    id,
    section_id,
    column_id,
    town_id,
    category_id,
    bullseye_ad_count,
  } = props || {};

  const {
    name,
    url_content: url,
    subheading,
    column_for_front_end: column,
    opinions: article_column_category,
    is_advertisement,
    promotional_footer,
    ad_free,
    disclaimer,
    submitted_via,
    has_audio_player,
    byline,
    humanized_published_at: published_at,
    humanized_updated_at: important_data_changed_at,
    get_town_admin_profile_path: town_admins_profile_page_path,
    media_content,
    current_layout_name,
    section_for_front_end,
    column_for_front_end,
    category_for_front_end,
  } = article;

  const isMobile = screenWidthIsLowerThan(values.sm);
  const largeScreen = screenWidthIsLowerThan(1025);
  const classes = useStyles();

  const leaderboardProps = useMemo(() => {
    if (isMobile) {
      return {
        top: { ad_unit: "leaderboard_mobile", size: [320, 100] },
        body: { ad_unit: "leaderboard_mobile_1", size: [320, 100] },
      };
    }
    return {
      top: { ad_unit: "tap_leaderboard_001", size: [728, 90] },
      body: { ad_unit: "tap_leaderboard_002", size: [728, 90] },
    };
  }, [isMobile]);

  return (
    <Grid container justifyContent="center">
      {!ad_free && (
        <Leaderboard
          props={{ ...leaderboardProps.top, targets: ad_targets }}
          options={{ margin_bottom: false }}
        />
      )}

      <Grid
        item
        xs={12}
        classes={{
          root: clsx(
            classes.top_section,
            is_article_preview && classes.preview_top_padding
          ),
        }}
      >
        <Grid container justifyContent="center">
          {breadcrumbs?.length > 0 && (
            <Grid
              item
              xs={12}
              classes={{ root: classes.breadcrumbs_container }}
            >
              <Breadcrumbs items={breadcrumbs} color="white" />
            </Grid>
          )}

          {can_edit && (
            <Grid item xs={12} classes={{ root: classes.edit_container }}>
              <Edit editUrl={edit_url} />
            </Grid>
          )}

          {is_advertisement && (
            <Grid
              item
              xs={12}
              classes={{ root: classes.sponsored_content_container }}
            >
              <Typography level="paragraph_1" color="white">
                {labels.SPONSORED_CONTENT}
              </Typography>
            </Grid>
          )}

          {sponsor && (
            <Grid item xs={12} classes={{ root: classes.sponsor_container }}>
              <Sponsor sponsor_info={sponsor} />
            </Grid>
          )}

          {(section_for_front_end?.name ||
            category_for_front_end?.name ||
            column_for_front_end?.name) && (
            <Grid
              item
              xs={10}
              md={9}
              classes={{ root: classes.section_tag_container }}
            >
              <SectionTag
                variant="layout_5"
                label={
                  section_for_front_end?.name ||
                  category_for_front_end?.name ||
                  column_for_front_end?.name
                }
                url={
                  section_for_front_end?.path ||
                  category_for_front_end?.path ||
                  column_for_front_end?.path
                }
              />
            </Grid>
          )}

          <Grid
            item
            xs={11}
            md={9}
            classes={{
              root: classes.header_container,
            }}
          >
            <Typography
              bold
              color="white"
              className={clsx(classes.header, HEADER_CONTAINER_SELECTOR)}
              level="h1_header_1"
            >
              {name}
            </Typography>
          </Grid>

          {subheading && (
            <Grid
              item
              xs={11}
              md={9}
              classes={{ root: classes.subheader_container }}
            >
              <Typography color="white" className={classes.subheader}>
                {subheading}
              </Typography>
            </Grid>
          )}

          <Grid
            item
            xs={12}
            classes={{ root: classes.carousel_and_ads_container }}
          >
            <Grid container spacing={1} classes={{ root: classes.row_gap }}>
              <Grid item xs={12} md={ad_free || largeScreen ? 12 : 8}>
                <ArticlesCarousel items={media_content} darkBackground />
              </Grid>

              {!ad_free && !isMobile && (
                <TopAds
                  video_ad_content={video_ad_content}
                  bullseye_ad_count={bullseye_ad_count}
                  ad_targets={ad_targets}
                  withWhiteBorders
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {!ad_free && isMobile && (
        <TopAds
          video_ad_content={video_ad_content}
          bullseye_ad_count={bullseye_ad_count}
          ad_targets={ad_targets}
        />
      )}

      {!ad_free && (
        <Leaderboard
          props={{ ...leaderboardProps.body, targets: ad_targets }}
        />
      )}

      <Grid item xs={12} md={11}>
        <Grid container classes={{ root: !is_article_preview ? classes.body_container : classes.article_preview_grid}}>
          <Grid
            item
            xs={12}
            classes={{
              root: classes.article_container,
            }}
          >
            <Grid container>
              {has_audio_player && (
                <Grid item xs={12} className={classes.audio_player_container}>
                  <AudioPlayer />
                </Grid>
              )}

              <Grid item xs={12}>
                <ByLine
                  author={byline}
                  published_at={published_at}
                  updated_at={important_data_changed_at}
                  author_email={town_editor_email}
                  town_admins_profile_page_path={town_admins_profile_page_path}
                  preview={is_article_preview}
                  title={name}
                  url={url}
                />
              </Grid>

              {!is_article_preview && (
                <StickySocialNetworkShareButtons top={window.innerHeight / 3} />
              )}

              <Grid item xs={12}>
                <GoogleReCaptchaProvider
                  useRecaptchaNet
                  reCaptchaKey={withConfig("RECAPTCHA_KEY")}
                  scriptProps={{
                    async: true,
                    defer: true,
                    appendTo: "body",
                  }}
                >
                  <ArticleBody
                    text={article_body}
                    column={column}
                    isAdvertisement={is_advertisement}
                    articleColumnCategory={article_column_category}
                    isAdFree={ad_free}
                    categoryName={category_name}
                    subscribeToContent={subscribe_to_content}
                    isArticlePreview={is_article_preview}
                    isTown={is_town}
                    isPremium={is_premium}
                    adTargets={ad_targets}
                  />
                </GoogleReCaptchaProvider>
              </Grid>

              {promotional_footer && (
                <Grid item xs={12}>
                  <PromotionalFooter promotionalFooter={promotional_footer} />
                </Grid>
              )}

              {disclaimer && (
                <Grid item xs={12}>
                  <EditorNotes editorNotes={disclaimer} />
                </Grid>
              )}

              <Grid item xs={12}>
                <Disclaimer
                  categoryName={category_name}
                  column={column}
                  isAdvertisement={is_advertisement}
                  articleColumnCategory={article_column_category}
                  submittedVia={submitted_via}
                  disclaimerPolicyPageLinks={disclaimer_policy_page_links}
                  customDisclaimer={article_disclaimer}
                />
              </Grid>
            </Grid>
          </Grid>

          {!is_article_preview && (
            <Grid item xs={12}>
              <MostRead
                variant={"layout_5"}
                articleProps={{
                  id,
                  section_id,
                  column_id,
                  town_id,
                  category_id,
                }}
              />
            </Grid>
          )}

          {!is_article_preview && (
            <Grid item xs={12}>
              <RelatedNews
                articleProps={{
                  id,
                  section_id,
                  column_id,
                  town_id,
                  category_id,
                }}
                variant={current_layout_name}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

Layout5.propTypes = {
  article: object.isRequired,
  props: object.isRequired,
  sponsor: object,
};

export default Layout5;
