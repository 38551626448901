import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { screenWidthIsLowerThan } from "../../../../hooks/useMediaQuery";
import { useStyles } from "./Layout3.style";
import clsx from "clsx";
import ArticleBody from "../../ArticleBody";
import AudioPlayer from "../../../AudioPlayer/AudioPlayer";
import Breadcrumbs from "../../../../common/Breadcrumbs/Breadcrumbs";
import ByLine from "../../ByLine/ByLine";
import ArticlesCarousel from "../../../../common/Carousel/Articles/ArticlesCarousel";
import Disclaimer from "../../Disclaimer/Disclaimer";
import Grid from "../../../../common/Grid/Grid";
import Leaderboard from "../Components/Leaderboard/Leaderboard";
import MostRead from "../../MostRead/MostRead";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import RelatedNews from "../../RelatedNews/RelatedNews";
import SectionTag from "../../SectionTag/SectionTag";
import Sponsor from "../../../Sponsor/Sponsor";
import TopAds from "../Components/TopAds/TopAds";
import Typography from "../../../../common/Typography/Typography";
import values from "../../../../common/Theme/themes/breakpoints/values";
import withConfig from "../../../../utils/withConfig";
import StickySocialNetworkShareButtons from "../../../StickySocialNetworkShareButtons/StickySocialNetworkShareButtons";
import PromotionalFooter from "../Components/PromotionalFooter/PromotionalFooter";
import EditorNotes from "../Components/EditorNotes/EditorNotes";
import labels from "../../../../config/labels";
import Edit from "../../../Actions/Edit";
import { HEADER_CONTAINER_SELECTOR } from "../../../../utils/constants/trinityAudioConstants";

const Layout3 = ({ article, props, sponsor }) => {
  const classes = useStyles();
  const {
    article_body,
    category_name,
    subscribe_to_content,
    is_article_preview,
    is_town,
    is_premium,
    ad_targets,
    article_disclaimer,
    disclaimer_policy_page_links,
    town_editor_email,
    can_edit,
    edit_url,
    video_ad_content,
    breadcrumbs,
    id,
    section_id,
    column_id,
    town_id,
    category_id,
    bullseye_ad_count,
  } = props || {};

  const {
    name,
    url_content: url,
    subheading,
    column_for_front_end: column,
    opinions: article_column_category,
    is_advertisement,
    promotional_footer,
    ad_free,
    disclaimer,
    submitted_via,
    has_audio_player,
    byline,
    humanized_published_at: published_at,
    humanized_updated_at: important_data_changed_at,
    get_town_admin_profile_path: town_admins_profile_page_path,
    media_content,
    current_layout_name,
    section_for_front_end,
    column_for_front_end,
    category_for_front_end,
  } = article;

  const isMobile = screenWidthIsLowerThan(values.sm);
  const largeScreen = screenWidthIsLowerThan(1025);
  const mediumScreen = screenWidthIsLowerThan(values.md);

  const leaderboardProps = useMemo(() => {
    if (isMobile) {
      return {
        top: { ad_unit: "leaderboard_mobile", size: [320, 100] },
        body: { ad_unit: "leaderboard_mobile_1", size: [320, 100] },
      };
    }
    return {
      top: { ad_unit: "tap_leaderboard_001", size: [728, 90] },
      body: { ad_unit: "tap_leaderboard_002", size: [728, 90] },
    };
  }, [isMobile]);

  return (
    <>
      <Grid
        container
        justifyContent={"center"}
        classes={{
          root: clsx(classes.container, ad_free && classes.margin_top),
        }}
      >
        {!ad_free && (
          <Leaderboard
            props={{ ...leaderboardProps.top, targets: ad_targets }}
          />
        )}
        {breadcrumbs?.length > 0 && (
          <Grid item xs={12} classes={{ root: classes.breadcrumbs_container }}>
            <Breadcrumbs items={breadcrumbs} />
          </Grid>
        )}

        {can_edit && (
          <Grid item xs={12} classes={{ root: classes.edit_container }}>
            <Edit editUrl={edit_url} />
          </Grid>
        )}

        {is_advertisement && (
          <Grid
            item
            xs={12}
            classes={{ root: classes.sponsored_content_container }}
          >
            <Typography level="paragraph_1">
              {labels.SPONSORED_CONTENT}
            </Typography>
          </Grid>
        )}

        {sponsor && (
          <Grid item xs={12} classes={{ root: classes.sponsor_container }}>
            <Sponsor sponsor_info={sponsor} />
          </Grid>
        )}
        {(section_for_front_end?.name ||
          category_for_front_end?.name ||
          column_for_front_end?.name) && (
          <Grid item xs={10} classes={{ root: classes.tag_container }}>
            <SectionTag
              variant="layout_3"
              label={
                section_for_front_end?.name ||
                category_for_front_end?.name ||
                column_for_front_end?.name
              }
              url={
                section_for_front_end?.path ||
                category_for_front_end?.path ||
                column_for_front_end?.path
              }
            />
          </Grid>
        )}
        <Grid item xs={10} classes={{ root: classes.header_container }}>
          <Typography
            bold
            level="h1_header_1"
            className={clsx(classes.header, HEADER_CONTAINER_SELECTOR)}
          >
            {name}
          </Typography>
        </Grid>
        {subheading && (
          <Grid item xs={10}>
            <Typography level="h3_sub_header" className={classes.subheader}>
              {subheading}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} classes={{ root: classes.carousel_and_ads }}>
          <Grid
            container
            justifyContent={is_article_preview ? "center" : "space-between"}
          >
            {media_content && (
              <Grid item xs={12} md={largeScreen || ad_free ? 12 : 8}>
                <ArticlesCarousel items={media_content} />
              </Grid>
            )}

            {!ad_free && (
              <TopAds
                video_ad_content={video_ad_content}
                bullseye_ad_count={bullseye_ad_count}
                ad_targets={ad_targets}
              />
            )}
          </Grid>
        </Grid>
        {!ad_free && (
          <Leaderboard
            props={{ ...leaderboardProps.body, targets: ad_targets }}
          />
        )}

        <Grid
          item
          xs={12}
          md={mediumScreen ? 12 : 10}
          classes={{ root: !is_article_preview ? classes.body_container : classes.article_preview_grid }}
        >
          {has_audio_player && (
            <Grid item xs={12} classes={{ root: classes.audio_container }}>
              <AudioPlayer />
            </Grid>
          )}

          <Grid item xs={12}>
            <ByLine
              author={byline}
              published_at={published_at}
              updated_at={important_data_changed_at}
              author_email={town_editor_email}
              preview={is_article_preview}
              town_admins_profile_page_path={town_admins_profile_page_path}
              title={name}
              url={url}
            />
          </Grid>

          {!is_article_preview && (
            <StickySocialNetworkShareButtons top={window.innerHeight / 3} />
          )}

          <Grid item xs={12}>
            <GoogleReCaptchaProvider
              useRecaptchaNet
              reCaptchaKey={withConfig("RECAPTCHA_KEY")}
              scriptProps={{
                async: true,
                defer: true,
                appendTo: "body",
              }}
            >
              <ArticleBody
                text={article_body}
                column={column}
                isAdvertisement={is_advertisement}
                articleColumnCategory={article_column_category}
                isAdFree={ad_free}
                categoryName={category_name}
                subscribeToContent={subscribe_to_content}
                isArticlePreview={is_article_preview}
                isTown={is_town}
                isPremium={is_premium}
                adTargets={ad_targets}
              />
            </GoogleReCaptchaProvider>
          </Grid>

          {promotional_footer && (
            <Grid item xs={12}>
              <PromotionalFooter promotionalFooter={promotional_footer} />
            </Grid>
          )}

          {disclaimer && (
            <Grid item xs={12}>
              <EditorNotes editorNotes={disclaimer} />
            </Grid>
          )}

          <Grid item xs={12}>
            <Disclaimer
              categoryName={category_name}
              column={column}
              isAdvertisement={is_advertisement}
              articleColumnCategory={article_column_category}
              submittedVia={submitted_via}
              disclaimerPolicyPageLinks={disclaimer_policy_page_links}
              customDisclaimer={article_disclaimer}
            />
          </Grid>
        </Grid>
        {!is_article_preview && (
          <Grid item xs={12} md={mediumScreen ? 12 : 11}>
            <MostRead
              articleProps={{
                id,
                section_id,
                column_id,
                town_id,
                category_id,
              }}
              variant={current_layout_name}
            />
          </Grid>
        )}
        {!is_article_preview && (
          <Grid
            item
            xs={12}
            md={mediumScreen ? 12 : 11}
            classes={{ root: classes.related_news_container }}
          >
            <RelatedNews
              articleProps={{
                id,
                section_id,
                column_id,
                town_id,
                category_id,
              }}
              variant={current_layout_name}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

Layout3.propTypes = {
  article: PropTypes.object.isRequired,
  props: PropTypes.object.isRequired,
  sponsor: PropTypes.object,
};

export default Layout3;
