import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./RelatedNews.style";
import HorizontalCard from "../HorizontalCard/HorizontalCard";
import ArticleGallery from "../ArticleGallery/ArticleGallery";
import Grid from "../../../common/Grid/Grid";
import Typography from "../../../common/Typography/Typography";
import labels from "../../../config/labels";
import useRelatedNews from "../../../hooks/ArticleShow/useRelatedNews";
import clsx from "clsx";

const RelatedNewsHeader = ({ variant }) => {
  const classes = useStyles();
  const defaultClasses = {
    text_label: classes[`text_label_${variant}`],
    label_container: classes[`label_container_${variant}`],
  };
  return (
    <Grid
      item
      xs={12}
      className={clsx(defaultClasses.label_container, classes.no_printing)}
    >
      <Typography level={"h3_sub_header"} className={defaultClasses.text_label}>
        {labels.RELATED_NEWS}
      </Typography>
    </Grid>
  );
};

const ListRelatedNews = ({ variant, items }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      className={classes.no_printing}
      classes={{ root: classes.list_related_news }}
    >
      {items.map((item, index) => (
        <Grid item key={index} xs={12}>
          <HorizontalCard key={index} item={item} variant={variant} />
        </Grid>
      ))}
    </Grid>
  );
};

const RelatedNews = ({ variant, articleProps }) => {
  const { relatedNews } = useRelatedNews(articleProps);
  const classes = useStyles();

  if (relatedNews?.length === 0) return <></>;

  switch (variant) {
    case "layout_1":
      if (relatedNews.length < 5) return <></>;
      return (
        <Grid container className={classes.related_news_layout_1}>
          <RelatedNewsHeader variant={variant} />
          <Grid item xs={12}>
            <ArticleGallery items={relatedNews} roundedCorners={false} />
          </Grid>
        </Grid>
      );
    case "layout_2":
      return (
        <Grid
          container
          spacing={4}
          classes={{ root: classes.container_layout_2 }}
        >
          <RelatedNewsHeader variant={variant} />
          <ListRelatedNews items={relatedNews} variant={variant} />
        </Grid>
      );
    case "layout_3":
      if (relatedNews.length < 5) return <></>;
      return (
        <Grid container className={classes.related_news_layout_3}>
          <RelatedNewsHeader variant={variant} />
          <ArticleGallery items={relatedNews} roundedCorners={false} />
        </Grid>
      );
    case "layout_4":
      return (
        <Grid
          container
          spacing={4}
          classes={{ root: classes.container_layout_4 }}
        >
          <RelatedNewsHeader variant={variant} />
          <ListRelatedNews items={relatedNews} variant={variant} />
        </Grid>
      );
    case "layout_5":
      if (relatedNews.length < 5) return <></>;
      return (
        <Grid container classes={{ root: classes.container_layout_5 }}>
          <RelatedNewsHeader variant={variant} />
          <ArticleGallery items={relatedNews} />
        </Grid>
      );
    default:
      return (
        <Grid container classes={{root: classes.no_printing}}>
          <RelatedNewsHeader variant={variant} />
          <ListRelatedNews items={relatedNews} variant={variant} />
        </Grid>
      );
  }
};
RelatedNews.propTypes = {
  variant: PropTypes.oneOf([
    "default",
    "layout_1",
    "layout_2",
    "layout_3",
    "layout_4",
    "layout_5",
  ]).isRequired,
  articleProps: PropTypes.shape({
    id: PropTypes.string,
    town_id: PropTypes.string,
    section_id: PropTypes.string,
    column_id: PropTypes.string,
    category_id: PropTypes.string,
  }).isRequired,
};

export default RelatedNews;
