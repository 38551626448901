import { makeStyles } from "../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    breakpoints: { values, down: below },
  } = theme;
  return {
    container: {
      width: "100% !important",
      margin: "0px !important",
    },
    ad_free_container: {
      paddingTop: `${spacing(5)}px !important`,
    },
    audio_player_container: {
      paddingBottom: spacing(3),
      "@media print": {
        display: "none",
      },
    },
    carousel_container_with_ad: {
      display: "flex",
      justifyContent: "space-between",
      gap: spacing(4),
    },
    sponsor_container: {
      display: "flex",
      justifyContent: "flex-end",
      "& [class^='sponsor_container']": {
        width: "fit-content !important",
        display: "flex",
        columnGap: spacing(2),
        paddingBottom: "0",
      },
      [below(values.md)]: {
        paddingRight: `${spacing(2)}px !important`,
      },
    },
    sponsored_content: {
      width: "100%",
      paddingBlock: theme.spacing(1.5),
      paddingRight: 0,
      textAlign: "right",
      opacity: "0.7",
      [below(values.md)]: {
        paddingRight: `${spacing(2)}px !important`,
      },
    },
    header_container: {
      margin: "0 !important",
      display: "flex",
      justifyContent: "flex-end",
      [below(values.md)]: {
        padding: `0px ${spacing(1)}px !important`,
      },
    },
    breadcrumbs_container: {
      borderBottom: `1px solid ${theme.palette.grayVariation.grayForBordersLight}`,
      paddingBottom: `${spacing(1.5)}px`,
      marginBottom: `${spacing(1.25)}px !important`,
    },
    article_body_container: {
      paddingInline: spacing(2),
    },
    article_preview_grid: {
      width: "100%",
      maxWidth: `${theme.spacing(139.5)}px !important`,
    },
    header: {
      fontWeight: 700,
      fontSize: `${theme.spacing(5)}px !important`,
      lineHeight: "48.76px",
      letterSpacing: "0.06em",
      textAlign: "center",
      [below(values.md)]: {
        fontSize: `${spacing(3)}px !important`,
        lineHeight: `${spacing(4)}px`,
      },
    },
    subheader: {
      padding: "0 !important",
      textAlign: "center",
      lineHeight: "48.76px",
      letterSpacing: "0.06em",
      textAlign: "center",
      [below(values.md)]: {
        fontSize: `${spacing(2)}px !important`,
        lineHeight: `${spacing(4)}px`,
        paddingBottom: `${spacing(2)}px !important`,
      },
    },
    most_popular_container: {
      padding: `${spacing(2)}px ${spacing(7)}px !important`,
      [below(values.md)]: {
        padding: `${spacing(2)}px ${spacing(2)}px !important`,
      },
    },
    tag_container: {
      marginBottom: `${spacing(5)}px !important`,
    },
    edit_container: {
      marginBottom: `${spacing(2)}px !important`,
      [below(values.md)]: {
        paddingRight: `${spacing(2)}px !important`,
      },
    },
    subheader_container: {
      [below(values.md)]: {
        padding: `0px ${spacing(2)}px !important`,
      },
    },
  };
});

export { useStyles };
