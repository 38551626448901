import React, { useEffect, useRef } from "react";

const RevContentWidget = ({ revContent }) => {
  const widgetRef = useRef(null);
  useEffect(() => {
    if (widgetRef.current) {
      const widgetId = revContent?.rc_widget_id;
      const dataWidgetId = revContent?.data_widget_id;
      widgetRef.current.id = `rc-widget${widgetId}`;
      widgetRef.current.setAttribute("data-widget-id", dataWidgetId);
      const script = document.createElement("script");
      script.src = "https://assets.revcontent.com/master/delivery.js";
      script.defer = true;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [revContent]);
  return (
    <div
      ref={widgetRef}
      data-rc-widget
      data-widget-host="habitat"
      data-endpoint="//trends.revcontent.com"
    ></div>
  );
};

export default RevContentWidget;
